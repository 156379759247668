@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@400;600;700&display=swap');
@import 'styles/variables';

//$base-href: '/themes/gamerz/assets/';
$base-href: '/assets/icons/menu/';
$base-primary: #bc56be;
$colors: (
  "reading": #bc56be,
  "math": #4098D6,
  "lectura": #E08C43,
  "matemáticas": #3CB09C,
  "grey-blue-900": #07090B
);
:root {
  --bg-primary: #D3E9F8;
  --color-text-light: #FAFCFF;
  --color-text-dark: #151924;
  --sky-150: #D6EDFF;
  --sky-200: #C3E4FF;
  --font-sans-fallback: -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

  // Base theme button properties
  --icon-size: 1em;
  --btn-padding-x: 1.5em;
  --btn-padding-y: 0.5em;
  --btn-text-size: 1.5rem;
  --btn-color: #ffffff;
  --btn-border-radius: 100rem;
  --btn-border-width: 0.188rem;
  --btn-hover-inset-color: currentColor;
  --btn-hover-inset-width: 0.188rem;
  --btn-hover-scale: 1.02;
  --btn-hover-distance: calc(-1 * 0.063rem);
  --btn-press-distance: 0.25rem;
  --btn-press-scale: 0.99;
  --btn-shadow: 0 0 0 0 transparent;
  --btn-shadow-press: 0 0 0 0 transparent;
  --btn-focusring-color: #5C23FF;
  --nav-avatar-size: calc(2.5rem + 1vh);
  --btn-font-size: 1.5rem;
  --btn-font-weight: 500;
  --btn-line-height: 1;
  --btn-font-family: var(--heading-font-family);
  --btn-bg-color: #ffdd64;
  --btn-color: #006698;

  --subject-card-btn-more-shadow: var(--subject-card-shadow);
  --subject-card-btn-more-padding: 1rem;

  // These are core styles we don't want to be inherited, but available persistantly
  --sys-font-family:  'Lexend', var(--font-sans-fallback);
  --sys-font-style: normal;
  --sys-font-weight: 400;
  --sys-font-size: 1rem;
  --sys-font-line-height: 1.15;
  --sys-font:
    var(--sys-font-style)
    var(--sys-font-weight)
    var(--sys-font-size) / var(--sys-font-line-height)
    var(--sys-font-family);
  --sys-color: black;

  --sys-heading-font-family: var(--sys-font-family); // use the same font for body and heading
  --sys-heading-font-weight: 700;
  --sys-heading-color: var(--sys-color, black);

  --sys-dialog-shadow: 0rem 0.125rem 0.5rem rgba(0, 0, 0, 0.08), 0rem 1.25rem 2rem rgba(0, 0, 0, 0.32);
  --sys-dialog-border-radius: 1.875rem;
  --sys-dialog-border: 0;
  --sys-dialog-bg: white;
  --sys-dialog-backdrop-bg: rgba(0, 7, 37, 0.6);
  --sys-dialog-backdrop-bg-filter: blur(0.438rem);
  --sys-dialog-content-padding: 0.75rem 2rem 2.375rem;
  --sys-dialog-header-padding: 0.75rem 2rem;
  --sys-dialog-footer-padding: var(--sys-dialog-header-padding);

  // Body definitions
  --body-bg-color-start: #F5FBFE;
  --body-bg-color-end: var(--bg-primary, '#7fa7cc');
  --body-bg-color: --body-bg-color-end;
  --body-bg-image: linear-gradient(to bottom, var(--body-bg-color-start, "#F5FBFE") 39.16%, var(--body-bg-color-end, "#D3E9F8") 85.42%);
  --body-bg-blend-mode: normal;
  --body-bg-repeat: no-repeat;
  --body-bg-position: center;
  --body-bg-size: contain;
  --body-bg:
      var(--body-bg-color)
      var(--body-bg-image)
      var(--body-bg-repeat)
      var(--body-bg-position) /
      var(--body-bg-size);
  --body-color: #212529;

  // Body type configuration
  --body-font-family: var(--sys-font-family);
  --body-font-style: var(--sys-font-style);
  --body-font-weight: var(--sys-font-weight);
  --body-font-size: var(--sys-font-size);
  --body-line-height: 1.5;
  --body-font:
      var(--body-font-style)
      var(--body-font-weight, normal)
      var(--body-font-size, 1rem)/var(--body-line-height)
      var(--body-font-family, Arial, Helvetica, sans-serif);

  // Heading (H1-H6) type configuration
  --heading-font-family: var(--sys-font-family);
  --heading-font-style: normal;
  --heading-font-weight: 700;
  --heading-font-size: 2rem; // this will be the heading base size
  --heading-line-height: 1;
  --heading-font:
      var(--heading-font-style)
      var(--heading-font-weight, normal)
      var(--heading-font-size, 1rem)/var(--heading-line-height)
      var(--heading-font-family);

  // Anchor Elements - We use this for button styling too
  --link-color: #386188;

  // Input and button generics
  --input-btn-color: #212529;
  --input-btn-font-family: var(--body-font-family);
  --input-btn-font-size: var(--body-font-size, 1rem);
  --input-btn-font-style: normal;
  --input-btn-font-weight: normal;
  --input-btn-line-height: 1.5;
  --input-btn-padding-y: 0.375rem;
  --input-btn-padding-x: 0.75rem;
  --input-btn-border-radius: 0.5rem;
  --input-btn-text-align: center;
  --input-btn-border-size: 0.063rem;
  --input-btn-border-style: solid;
  --input-btn-border-color: rgba(0,0,0,.25);
  --input-btn-border: var(--input-btn-border-size) var(--input-btn-border-style) var(--input-btn-border-color);
  --input-btn-bg: #fff;

  --select-color: var(--input-btn-color);
  --select-font-family: var(--input-btn-font-family);
  --select-font-weight: var(--input-btn-font-weight);
  --select-font-style: var(--input-btn-font-style);
  --select-font-size: var(--input-btn-font-size);
  --select-line-height: var(--input-btn-line-height);
  --select-padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  --select-border-radius: var(--input-btn-border-radius);
  --select-bg-color: var(--input-btn-bg);
  --select-bg-repeat: no-repeat;
  --select-bg-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"%3e%3cpath fill="none" stroke="%23343a40" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2 5l6 6 6-6"/%3e%3c/svg%3e');
  --select-bg-position: right 0.75rem center;
  --select-bg-size: 1rem 0.75rem;
  --select-bg:
    var(--select-bg-color)
    var(--select-bg-image)
    var(--select-bg-repeat)
    var(--select-bg-position) / var(--select-bg-size);

  // Button specific overrides
  --btn-font-family: var(--input-btn-font-family);
  --btn-font-style: var(--input-btn-font-style);
  --btn-font-size: 1.5rem;
  --btn-font-weight: 700;
  --btn-line-height: 1.17;
  --btn-font:
      var(--btn-font-style)
      var(--btn-font-weight)
      var(--btn-font-size, 1rem)/var(--btn-line-height)
      var(--btn-font-family);

  --btn-padding-y: 0.5em;
  --btn-padding-x: 0.8em;
  --btn-border-radius: 3.125rem;
  --btn-text-align: center;
  --btn-border-size: .1875rem; // var(--input-btn-border-size);
  --btn-border-style: solid; //var(--input-btn-border-style);
  --btn-border-color: white; //var(--input-btn-border-color);
  --btn-border: var(--btn-border-size) var(--btn-border-style) var(--btn-border-color);

  --btn-3d-depth: .3125rem; /* y value */
  --btn-3d-depth-color: transparent;
  --btn-hover-inset-color: currentColor;
  --btn-hover-inset-width: .1875rem;
  --btn-hover-scale: 1.02;
  --btn-hover-distance: calc(-1 * 0.063rem);
  --btn-press-distance: .25rem;
  --btn-press-scale: 0.99;
  --btn-disabled-bg-color: #c6c6c6;
  --btn-shadow: 0 0 0 0 transparent;
  --btn-utility-shadow-1: 0 0 0 0 transparent;
  --btn-utility-shadow-2: 0 0 0 0 transparent;

  --btn-color: #006698;
  --btn-bg-color: #FFCD1E;
  --btn-bg-image: none;
  --btn-bg-repeat: no-repeat;
  --btn-bg-position: center;
  --btn-bg-size: cover;

  --btn-action-color: var(--btn-color);
  --btn-action-width: 5rem;
  --btn-action-height: var(--btn-action-width);
  --btn-action-border: var(--btn-border);
  --btn-action-bg: var(--btn-bg);
  --btn-action-shadow: var(--btn-shadow);
  --btn-action-padding-x: 1rem;
  --btn-action-padding-y: 1rem;
  --btn-action-shadow-active: var(--btn-action-shadow);

  /* Button states */
  /** Default **/
  --btn-color: #006698;
  --btn-border: var(--btn-border-size) var(--btn-border-style) var(--btn-border-color);
  --btn-bg: var(--input-btn-bg);
  --btn-shadow: 0 .25rem rgba(0, 0, 0, 0.25);

  /** Hover **/
  --btn-color-hover: blue;
  --btn-bg-color-hover: yellow;
  --btn-border-hover: var(--btn-border-size) var(--btn-border-style) var(--btn-color-hover);
  --btn-bg-hover: var(--btn-bg-color-hover);
  --btn-shadow-hover: none;

  /** Focus **/
  --btn-color-focus: blue;
  --btn-border-focus: var(--btn-border-size) var(--btn-border-style) var(--btn-color-focus);
  --btn-bg-focus: yellow;
  --btn-shadow-focus: none;
  --btn-focus-outline-color: rgba(255, 51, 102, 0.9);
  --btn-focus-outline-offset: 0.5rem;

  /** Active **/
  --btn-color-active: blue;
  --btn-border-active: var(--btn-border-size) var(--btn-border-style) var(--btn-color-active);
  --btn-bg-active: yellow;
  --btn-shadow-active: none;

  /** Disabled **/
  --btn-color-disabled: #c6c6c6;
  --btn-border-disabled: var(--btn-border-disabled);
  --btn-bg-disabled: #c6c6c6;
  --btn-shadow-disabled: none;

  /* Header */
  --nav-font-family: var(--sys-font-family);
  --nav-font-weight: 700;
  --nav-height: 5.875rem;
  --nav-logo-img: url(assets/logos/logo-cape.png);
  --nav-logo-img-student-inbox: url(assets/nav/icon-logout.svg);
  --nav-bg: none; // rgba(255,255,255, 0.1);
  --nav-shadow: none; // 0 2px 2px rgba(0,0,0,0.2);
  --nav-stars-bg-color: #9e9e9e;
  --nav-stars-bg-img: url(assets/icons/star-shiny.svg);
  --nav-stars-bg-img-repeat: no-repeat;
  --nav-stars-bg-img-position-x: right 0.625rem;
  --nav-stars-bg-img-position-y: center;
  --nav-stars-bg-img-size: 2.5rem 2.375rem;
  --nav-stars-single-white-img: url(assets/icons/star-white.svg);
  --nav-stars-single-img-size: 52px 52px;
  --nav-stars-single-img-position-x: right 4px;
  --nav-stars-bg-img-size: 2.5rem 2.375rem;
  --nav-stars-bg:
    var(--nav-stars-bg-color)
    var(--nav-stars-bg-img)
    var(--nav-stars-bg-img-repeat)
    var(--nav-stars-bg-img-position-x)
    var(--nav-stars-bg-img-position-y) /
    var(--nav-stars-bg-img-size);
  --nav-stars-single-white:
    var(--nav-stars-single-white-img)
    var(--nav-stars-bg-img-repeat)
    var(--nav-stars-single-img-position-x)
    var(--nav-stars-bg-img-position-y) /
    var(--nav-stars-single-img-size);
  --nav-stars-border-radius: 3.125rem;
  --nav-stars-text-color: #ffffff;
  // used for calculating nav-star-counter positioning
    // when it outside of the nav-bar
  --nav-padding-top: 1rem;
  --nav-padding-right: 1.5rem;
  --nav-padding-bottom: 1rem;
  --nav-padding-left: 0.625rem;
  --nav-avatar-margin-left: 1.25rem;
  --nav-avatar-size: 3.75rem;
  --nav-btn-color: white;
  --nav-btn-border: .125rem solid var(--nav-btn-color);

  // Subject Cards

  /* These CSS custom properties are largely borrowed from the values used for band 3 */
  --subject-card-width: 100%;
  --subject-card-height: 30.125rem;
  --subject-card-margin: 0 0 2rem;
  --subject-card-padding: 1rem 1rem 2.5rem;
  --subject-card-column-gap: 1rem;
  --subject-card-shadow: 0rem 0rem 0.75rem rgba(10, 52, 113, 0.4);
  --subject-card-bg-color: rgb(255,255,255);
  --subject-card-bg-img: none;
  --subject-card-bg-img-repeat: no-repeat;
  --subject-card-bg-img-size: contain;
  --subject-card-bg-img-position-y: center;
  --subject-card-bg-img-position-x: center;
  --subject-card-bg:
    var(--subject-card-bg-color)
    var(--subject-card-bg-img, none)
    var(--subject-card-bg-img-repeat, no-repeat)
    var(--subject-card-bg-img-position-x, center)
    var(--subject-card-bg-img-position-y, center) /
    var(--subject-card-bg-img-size, contain);
  --subject-card-border-size: 0;
  --subject-card-border-color: transparent;
  --subject-card-border-style: solid;
  --subject-card-border:
    var(--subject-card-border-size, "0.25rem")
    var(--subject-card-border-style, solid)
    var(--subject-card-border-color, black);
  --subject-card-border-radius: 0.5rem;
  --subject-card-text-shadow: none;

  --subject-card-img-width: 100%;
  --subject-card-img-height: 76%;
  --subject-card-img-shadow: inset 0 0.25rem 1.25rem rgb(0,0,0);
  --subject-card-img-inner-shadow: var(--subject-card-img-shadow);
  --subject-card-img-border-radius: var(--subject-card-border-radius);
  --subject-card-img-margin: 0;

  // Button styling
  --subject-card-btn-width: 100%;
  --subject-card-btn-height: 3rem;
  --subject-card-btn-color: var(--btn-color);
  --subject-card-btn-scale-hover: 1.02;
  --subject-card-btn-scale-active: 0.98;
  --subject-card-btn-bg: var(--btn-bg);

  --subject-card-btn-play-icon-size: 1.2rem;
  --subject-card-btn-play-width: auto;
  --subject-card-btn-play-height: var(--subject-card-btn-height);
  --subject-card-btn-play-border: var(--btn-border);
  --subject-card-btn-play-color: var(--subject-card-btn-color);
  --subject-card-btn-play-bg: var(--subject-card-btn-bg);
  --subject-card-btn-play-shadow: var(--btn-shadow);
  --subject-card-btn-play-text-shadow: var(--subject-card-text-shadow);
  --subject-card-btn-play-border-radius: 6.25rem;
  --subject-card-btn-play-margin: calc(var(--subject-card-btn-play-height) / -2) 0 calc(var(--subject-card-btn-play-height) / 2);
  --subject-card-btn-play-padding: 1.25rem 2.25rem;

  --subject-card-btn-more-width: var(--subject-card-btn-width);
  --subject-card-btn-more-height: 4rem;
  --subject-card-btn-more-bg: black;
  --subject-card-btn-more-shadow: var(--btn-shadow);
  --subject-card-btn-more-text-shadow: var(--subject-card-text-shadow);
  --subject-card-btn-more-border-radius: 0;
  --subject-card-btn-more-color: white;
  --subject-card-btn-more-padding: 0.75em 1rem;
  --subject-card-btn-more-font-size: 1.125rem;
  --subject-card-btn-more-font-family: var(--btn-font-family);
  --subject-card-btn-more-font-weight: var(--btn-font-weight);
  --subject-card-btn-more-line-height: 1.17;

  --subject-card-header-width: 100%;
  --subject-card-header-padding: 1rem 0;
  --subject-card-header-margin: 0 0 1rem;
  --subject-card-header-bg-color: transparent;
  --subject-card-header-bg: var(--subject-card-header-bg-color, #FE9900);
  --subject-card-header-font: var(--heading-font);
  --subject-card-header-border-size: var(--subject-card-border-size);
  --subject-card-header-border-color: var(--subject-card-border-color);
  --subject-card-header-border-style: var(--subject-card-border-style);
  --subject-card-header-border: var(--subject-card-header-border-size) var(--subject-card-header-border-style) var(--subject-card-header-border-color);
  --subject-card-header-color: black;
  --subject-card-header-text-shadow: var(--subject-card-text-shadow);
  --subject-card-header-text-stroke: none;
  --subject-card-header-text-align: left;
  --subject-card-header-letter-spacing: -0.03em;
  --subject-card-header-overflow: hidden;

  --subject-card-body-padding: 3.75rem 2rem 2.625rem;
  --subject-card-body-align: center;
  --subject-card-body-color: white;

  --subject-card-heading-padding: 0; //padding on h2, h3, etc. elements in the subject card
  --subject-card-heading-margin: 0; //margin on h2, h3, etc. elements in the subject card

  --subject-card-footer-bg-width: 2.5rem;


  /* Subject navigator */
  --subject-navigator-option-text-color: #FFFFFF;
  --subject-navigator-option-box-shadow: 0rem 0rem 0.75rem rgba(10, 52, 113, 0.4);
  --subject-navigator-option-border: none;

  // Path sequence (linear learning path)
  --path-sequence-background: #ddd;

  // User Rating Window
  @for $i from 0 through 2 {
    --user-rating-face-#{$i}: url(assets/isip/face-#{$i}.svg);
    --user-rating-face-selected-#{$i}: url(assets/isip/face-selected-#{$i}.svg);
  }

  --wasm-bg: linear-gradient(to bottom, rgb(67, 73, 86), black);
  // for results-graph page
  --isip-start-info-icon: url(assets/isip/icon-info.svg);
  --isip-start-calandar-icon: url(assets/isip/icon-calendar.svg);
  --isip-results-icon-highest-img: url(assets/isip/accolades.svg);
  --isip-results-icon-lowest-img: url(assets/isip/target-miss.svg);
  --isip-results-icon-highest-static-img: url(assets/isip/accolades-static.svg);
  --isip-results-icon-lowest-static-img: url(assets/isip/target-miss-static.svg);
  --isip-results-icon-goal-met-img: url(assets/isip/green-checkmark.svg);
  --isip-star-filled-icon: url(assets/isip/star-filled.svg);

  //z indexes
  @each $name, $index in $zindex {
    --z-index-#{$name}: #{$index};
  }
}

.btn-system-primary {
  --btn-bg-color: #1e0065;
  --btn-border-color: var(--btn-bg-color);
  --btn-border-hover-color: var(--btn-border-color);
  --btn-border: var(--btn-border-size) var(--btn-border-style) var(--btn-bg-color);
  --btn-3d-depth-color: transparent;
  --btn-3d-bg: 0 var(--btn-3d-depth) 0 0 var(--btn-3d-depth-color);
}

.btn-system-secondary {
  --btn-bg-color: #c1bfff;
  --btn-color: #1e0065;
  --btn-border-color: var(--btn-bg-color);
  --btn-border-hover-color: var(--btn-bg-color);
  --btn-border: var(--btn-border-size) var(--btn-border-style) var(--btn-bg-color);
  --btn-hover-inset-color: #1e0065;
  --btn-3d-depth-color: transparent;
  --btn-3d-bg: 0 var(--btn-3d-depth) 0 0 var(--btn-3d-depth-color);
}

.btn-system-tertiary {
  --btn-3d-depth: 0;
  --btn-3d-depth-color: transparent;
  --btn-hover-inset-color: transparent;
  --btn-hover-inset-width: 0;
  --btn-hover-distance: 0;
  --btn-hover-scale: 1;
  --btn-press-distance: 0;
  --btn-press-scale: 1;
  --btn-3d-bg: 0 var(--btn-3d-depth) 0 0 var(--btn-3d-depth-color);
}

.btn-primary {
  --btn-border-color: #ffffff;
  --btn-border-hover-color: var(--btn-border-color);
  --btn-3d-bg: 0 var(--btn-3d-depth) 0 0 var(--btn-3d-depth-color);
  --btn-3d-bg-press: 0 calc(var(--btn-3d-depth) - var(--btn-press-distance)) var(--btn-3d-depth-color);
}

.btn-secondary {
  --btn-bg-color: #006698;
  --btn-border-color: var(--btn-bg-color);
  --btn-color: #ffffff;
  --btn-border-hover-color: var(--btn-bg-color);
  --btn-hover-inset-color: #ffffff;
  --btn-padding-x: 1rem;
  --btn-padding-y: 1rem;
  --btn-3d-bg: 0 var(--btn-3d-depth) 0 0 var(--btn-3d-depth-color);
  --btn-3d-bg-press: 0 calc(var(--btn-3d-depth) - var(--btn-press-distance)) var(--btn-3d-depth-color);
}

.btn-tertiary {
  --btn-bg-color: transparent;
  --btn-3d-depth-color: transparent;
  --btn-3d-bg: 0 var(--btn-3d-depth) 0 0 var(--btn-3d-depth-color);
  --btn-border-color: currentColor;
  --btn-border-width: 0.188rem;
  --icon-size: 2rem;
  --btn-shadow: 0;
  --btn-padding-x: 0.5em;
  --btn-padding-y: 0.5em;
  --btn-press-scale: 0.99;
  --btn-hover-inset-color: #ffffff;
  --btn-border-hover-color: var(--btn-border-color);
  --btn-border: var(--btn-border-size) var(--btn-border-style) var(--btn-color);
  --btn-3d-bg-press: 0 calc(var(--btn-3d-depth) - var(--btn-press-distance)) var(--btn-3d-depth-color);
}

.btn-more {
  --btn-bg-color: var(--btn-more-bg-color);
  --btn-bg-image: var(--btn-more-bg-image); //for synthwave more button bg gradient
  --btn-3d-depth-color: var(--btn-more-3d-depth-color);
  --btn-3d-bg: 0 var(--btn-3d-depth) 0 0 var(--btn-3d-depth-color);
  --btn-3d-bg-press: 0 calc(var(--btn-3d-depth) - var(--btn-press-distance)) var(--btn-3d-depth-color);
  --btn-border-color: var(--btn-more-border-color);
  --btn-border-hover-color: var(--btn-more-border-hover-color);
  --btn-border: var(--btn-border-size) var(--btn-border-style) var(--btn-more-border-color);
  --btn-color: #ffffff;
  --btn-border-radius: 0.5rem;
  --btn-shadow: 0rem 0rem 0.75rem rgba(10, 52, 113, 0.4);
  --btn-font-family: var(--heading-font-family);
  --btn-more-font-size: 1.5rem;
  --btn-more-font-weight: 500;
  --btn-font:
  var(--btn-font-style)
  var(--btn-more-font-weight)
  var(--btn-more-font-size)
  var(--btn-font-family);
}

//set subject card colors for btn-more
.card-footer {
  --btn-more-bg-image: none;
  --btn-more-bg-color: var(--subject-card-base-color);
  --btn-more-3d-depth-color: transparent;
  --btn-3d-bg: 0 var(--btn-3d-depth) 0 0 var(--btn-3d-depth-color);
  --btn-3d-bg-press: 0 calc(var(--btn-3d-depth) - var(--btn-press-distance)) var(--btn-3d-depth-color);
  --btn-more-border-color: var(--subject-card-base-color);
  --btn-more-border-hover-color: var(--subject-card-base-color);
  --btn-more-font-weight: 500;
}

//set subject navigator colors for btn-more
.option-wrapper,
.explorer-button {
  --btn-more-bg-image: none;
  --btn-bg-color: var(--subject-navigator-option-bg);
  --btn-more-3d-depth-color: transparent;
  --btn-3d-bg: 0 var(--btn-3d-depth) 0 0 var(--btn-more-3d-depth-color);
  --btn-3d-bg-press: 0 calc(var(--btn-3d-depth) - var(--btn-press-distance)) var(--btn-3d-depth-color);
  --btn-more-border-color: var(--subject-navigator-option-bg);
  --btn-more-border-hover-color: var(--subject-navigator-option-bg);
}

.option-wrapper {
  --option-wrapper-padding: 0 .75rem 0 7.1875rem;
}

/*
.rating-btn {
  --btn-border-color: white;
}*/

@each $subject, $color in $colors {
  .card-#{$subject} {
    --subject-card-base-color: #{$color};
    --subject-card-base-tint: #{lighten(saturate($color, 4%), 15%)};
    --subject-card-base-shade: #{darken($color, 20%)};
    --subject-card-header-color: var(--subject-card-base-color);
    --subject-card-btn-more-bg: var(--subject-card-base-tint);
    --subject-card-img-border: 0.188rem solid var(--subject-card-base-color);
  }

  .explorer-#{$subject} {
    --subject-explorer-title-text-color: #{$color};
  }

  @each $subject, $bg in $navigarorOptionsColors {
    .option-#{$subject}, .explorer-#{$subject} {
      --subject-navigator-option-bg: #{$bg};
    }
  }
}

@each $subject, $color in $colors {
  .card-#{$subject} {
    --subject-card-header-bg-img: url(#{$base-href + 'header-' + $subject + '.svg'});
    --subject-card-footer-bg-img: url(#{$base-href + 'footer-' + $subject + '.svg'});
  }
}
